<template>
  <b-sidebar
    id="order-tracking-modal"
    ref="modal"
    header-class="headerModalToggle"
    right
    :title="`${$t('OrderItemTracking')}`"
    shadow
    no-close-on-esc
    width="70%"
  >
    <template #title>
      <strong style="color: #101828;">{{ `${$t('OrderItemTracking')}` }}</strong>
    </template>
    <template
      v-slot:header-close
    >
      <button
        style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px; "
        class="fa fa-close"
        @click="onCancelToggle"
      />
    </template>
    <div style="padding: 20px;">
      <div
        class="tt"
        style=" padding-top: 16px"
      >
        <table
          class="team_table"
        >
          <thead style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left">
            <tr>
              <th style="padding: 10px; text-align: left; border-radius: 0px 0px 0px 0px;; color:#262E6C !important; font-size: 15px; ">
                {{ $t('OrderNumber') }}
              </th>
              <th style="padding: 10px; text-align: left; color:#262E6C !important; font-size: 15px;">
                {{ $t('ArticleName') }}
              </th>
              <th style="padding: 10px; text-align: left; color:#262E6C !important; font-size: 15px;">
                {{ $t('State') }}
              </th>
              <th style="padding: 10px; text-align: left; color:#262E6C !important; font-size: 15px;">
                {{ $t('Time') }}
              </th>
              <th style="padding: 10px; text-align: left; color:#262E6C !important; font-size: 15px;">
                {{ $t('ExecutorName') }}
              </th>
              <th style="padding: 10px; text-align: left; color:#262E6C !important; font-size: 15px;">
                {{ $t('ProcessedBy') }}
              </th>
              <th style="padding: 10px; text-align: left;border-radius: 0px 0px 0px 0px; color:#262E6C !important; font-size: 15px;">
                {{ $t('Message') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for=" ( item ,index) in getOrderTrackItems"
              :key="index"
              class="hover-row"
              style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor:auto;"
            >
              <td style="padding: 10px; text-align: left;color: black; font-weight: 400;">
                {{ $t(item.orderNumber) }}
              </td>
              <td style="padding: 10px; text-align: left; color: black; font-weight: 400;">
                {{ item.windowItemName }}
              </td>
              <td style="padding: 10px; text-align: left;color: black; font-weight: 400;">
                {{ item.state }}
              </td>
              <td style="padding: 10px; text-align: left;color: black; font-weight: 400;">
                {{ createdDateModified(item.created) }}
              </td>
              <td style="padding: 10px; text-align: left;color: black; font-weight: 400;">
                {{ item.executorName }}
              </td>
              <td style="padding: 10px; text-align: left;color: black; font-weight: 400;">
                {{ item.processingUserName }}
              </td>
              <td style="padding: 10px; text-align: left;color: black; font-weight: 400;">
                {{ item.message }}
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <div v-if="getTotalItemsForOrderTrackItems > 15">
            <b-pagination
              v-model="page"
              :total-rows="getTotalItemsForOrderTrackItems"
              :per-page="pageSize"
              first-number
              last-number
              align="fill"
              prev-class="prev-itemi"
              next-class="next-itemi"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
              </template>
              <template #next-text>
                <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  props: ['orderid', 'orderss'],
  data() {
    return {
      page: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapGetters(['getHistoryTracking', 'getOrderTrackItems', 'getTotalItemsForOrderTrackItems']),
  },
  watch: {
    page(value) {
      this.loadOrderTrackByOnAndAn({
        orderNumber: this.orderss,
        orderItemId: this.orderid,
        pageNumber: value,
        pageSize: this.pageSize,
      })
    },
  },
  created() {
  },
  methods: {
    ...mapActions(['loadOrderedMaterials', 'loadOrderTrackByOnAndAn', 'loadOrderItems']),
    createdDateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY - HH:mm');
    },
    onCancelToggle() {
      this.page = 1
      this.$emit('onCancelToggle');
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },

  },
};
</script>

<style scoped lang="scss">
#sidebar-right{
  z-index: 1001;
}
        .hover-row:hover {
          background-color: #f5f5f5; /* Set your desired background color here */
        }
      .mats-listing__wrapper {
        width: 600px !important;

        .tt {
          .vgt-table {
            th.sortable button {
              display: none !important;
            }
          }
        }

        .mats-listing {
          margin-top: 0;
        }
      }

      .button {
        margin-bottom: 15px !important;
        width: 195px;
      }

      .team_table {
        font-size: 12px;
        border-radius: 8px;
        width: 100%;
        box-shadow: none;
      }

      // .team_table .tr{
      //   border-radius: 10px 10px 10px 10px !important;
      // }
      tr:last-child {
        border-bottom: none !important;
      }

      @media screen and (max-width: 1723px) {
        .mats-listing__wrapper {
          width: 400px !important;

          div {
            button {
              width: auto;
              height: 36px;
            }

            input {
              width: 160px;
            }

          }

          .table__search-input {
            padding-left: 0px;
            margin-left: 0px !important;
          }
        }

      }

      @media screen and (max-width: 1400px) {
        .mats-listing__wrapper {
          width: 100% !important;
        }
      }


      .dropdrop::-webkit-scrollbar {
        display: none;

      }

      .dropdrop {
        display: inline-block;
        overflow: auto;
        position: absolute;
        background: white;
        width: 227px;
        box-shadow: none;
      }

      input[name="search-box"] {
        display: block;
        max-width: 500px;
        width: 95%;
        padding: 0.5rem 1rem;
        padding-left: 25px;
      }

      .black {
        position: relative;
      }

      .black input {
        border: none;
        box-shadow: none;
      }

      .search-item {
        padding: 5px;
        max-width: 500px;
        width: 100%;
        font-weight: 400;
        color: #82868c;
        font-size: 1rem;
        border-bottom: 1px solid #e8e8e8;
      }

      .search-item:last-child {
        border-bottom: none;
      }

      .search-item:hover {
        background: #82868c;
        color: white;
      }

      .search-item hr {
        color: lightgray;
        border-top: none;
        margin: -1.3rem 0 0.5rem 0;
      }

      .is-active {
        background-color: #dedede;
      }
      .headerModal{
        color: red;
      }
</style>
